section.team-members {
  padding: 60px 0px 90px;
}
.activites-list .single-activity {
  border: 1px solid #c8c8c8;
}
.activites-list .single-activity .member-data a {
  text-decoration: none;
  color: #1da59a;
  font-size: 16px;
  font-family: "philosopher-bold";
}
.activites-list .single-activity .member-data {
  padding: 23px 17px;
}
.activites-list .single-activity .member-data h4 {
  color: #000000;
  font-size: 26px;
  font-family: "philosopher-bold";
  margin-bottom: 0px;
}

.activites-list .single-activity .member-data p {
  font-size: 12px;
  color: #000000;
  line-height: 1.4;
  margin-top: 3px;
}

.activites-list .single-activity figure img {
  height: 240px;
  object-fit: cover;
}

.conference-list .single-conference .confoerence-data .text h6.small-heading {
  margin-bottom: 0pc;
}

.conference-list .single-conference .confoerence-data .text h3.large-heading {
  line-height: 1;
}

.conference-list .single-conference .confoerence-data .text p {
  font-size: 13px;
}

.conference-list .single-conference .confoerence-data .speakers h5 {
  color: #191919;
  font-size: 21px;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .activites-list
  .single-activity
  .member-data
  h4 {
  font-size: 12px;
  line-height: 1.2;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .activites-list
  .single-activity
  .member-data
  h5.small-pnik-heading {
  font-size: 9px;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .activites-list
  .single-activity
  figure
  img {
  height: 112px;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .activites-list
  .single-activity
  .member-data {
  padding: 10px 4px 8px;
}
@media (max-width: 1400px) {
  .conference-list .single-conference .confoerence-data .text h3.large-heading {
    font-size: 38px;
  }

  .conference-list .single-conference .confoerence-data .text p {
    font-size: 12px;
  }

  .conference-list
    .single-conference
    .confoerence-data
    .speakers
    .activites-list
    .single-activity
    figure
    img {
    height: 100px;
  }
}

@media (max-width: 767px) {
  .conference-list .single-conference .confoerence-data .text h3.large-heading {
    font-size: 24px;
  }
  .conference-list
    .single-conference
    .confoerence-data
    .speakers
    .members-list
    .single-member
    figure
    img {
    height: auto !important;
    object-position: top center !important;
  }
}
