/* Fonts Imported Here */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
/* Style Css Start Here */

/* Universal Css Start Here */
body {
  font-family: var(--font-default);
  overflow-x: hidden;
}

figure {
  margin-bottom: 0;
}

img {
  -webkit-user-drag: none;
}

/* Universal Css End Here */

/* Typography Start Here */
h1 {
  font-family: var(--font-heading);
  font-size: 90px;
  line-height: 100px;
}

h2 {
  font-family: var(--font-heading);
  font-size: 56px;
  line-height: 75px;
}

h3.large-heading {
  font-size: 43px;
}

h3 {
  font-family: var(--font-heading);
  font-size: 34px;
  line-height: 1.3;
}

h4 {
  font-family: var(--font-heading);
  font-size: 20px;
  line-height: 30px;
}

h5 {
  font-size: 26px;
  font-family: var(--font-heading);
  line-height: 1.3;
}

h6 {
  font-size: 18px;
  font-family: var(--bold-font);
  line-height: 1.3;
}

h6.small-heading {
  color: #1da59a;
  font-family: "philosopher-bold";
}

h6.pop-bold {
  font-size: 16px;
  font-family: var(--bold-font);
}

p {
  font-size: 13px;
  font-family: var(--font-default);
  line-height: 1.3;
}

p.small-text {
  font-size: 11px;
}

.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4,
.line-clamp-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.btn {
  font-size: 12px;
  font-family: var(--font-btn);
  color: #000000;
  background-color: #fec265;
  border-radius: 9px;
  border: 1px solid transparent;
  padding: 13px 40px 12px;
  display: block;
  width: fit-content;
  border: 1px solid transparent;
}

.btn:hover {
  border: 1px solid #fec265;
  color: #fec265;
}

.form-control {
  border: 1px solid #c3cad9;
  height: 49px;
  width: 100%;
  border-radius: 2px;
  background-color: #f3f3f3;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #183b56;
  padding: 5px 20px;
}

.form-group label {
  color: #000000;
  font-size: 13px;
  font-family: "poppins-regular";
  margin-bottom: 9px;
}

.form-control::placeholder {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #183b5690;
}

.form-control:focus {
  background-color: #c3cad9;
  box-shadow: none;
}

/* Custom CheckBox Black Default */
.custom-check-box-default .label-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 4px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
}

.custom-check-box-default .label-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-check-box-default .label-checkbox .checkmark {
  position: absolute;
  top: 5px;
  left: 2px;
  height: 12px;
  width: 12px;
  background-color: transparent;
  border: 1px solid;
}

/* On mouse-over, add a grey background color */
.custom-check-box-default .label-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-check-box-default .label-checkbox input:checked ~ .checkmark {
  background-color: transparent;
  border: 1px solid;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check-box-default .label-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-check-box-default .label-checkbox .checkmark:after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid #000;
  border-width: 0 1px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Custom CheckBox Black Default End Here */
/* Typography End Here */
/* Universal Css End Here */
/* Header Css Start Here */
.headerTop {
  background: var(--extra-color-1);
  padding: 15px 0px 20px;
}

.headerTop ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.headerTop ul li {
  display: inline-block;
}

.headerTop .navbar-menus ul.top-menus {
  text-align: end;
  position: relative;
  padding-bottom: 5px;
}

.headerTop .navbar-menus ul.bottom-menus {
  text-align: end;
  padding-top: 5px;
}

.headerTop .navbar-menus ul .menu-items a,
.headerTop .navbar-menus ul .menu-items .drop-header-btn {
  font-size: 14px;
  font-family: "poppins-medium";
  color: #fff;
  text-decoration: none;
  padding: 10px 10px;
  position: relative;
  background-color: transparent;
  border: 0px;
}

.headerTop .navbar-menus ul .menu-items a:after,
.headerTop .navbar-menus ul .menu-items button:after {
  content: "";
  background-image: linear-gradient(
    to bottom,
    #808080,
    #979797,
    #aeaeae,
    #c6c6c6,
    #dedede,
    #dedede,
    #dedede,
    #dedede,
    #c6c6c6,
    #aeaeae,
    #979797,
    #808080
  );
  width: 1px;
  height: 25px;
  position: absolute;
  top: 8px;
  right: 0;
  opacity: 0.7;
}

.headerTop .navbar-menus ul.top-menus:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.3;
  background-image: linear-gradient(
    to left,
    #d4d4d4,
    #dcdcdc,
    #e4e4e4,
    #ececec,
    #f4f4f4,
    #f4f4f4,
    #f4f4f4,
    #f4f4f4,
    #ececec,
    #e4e4e4,
    #dcdcdc,
    #d4d4d4
  );
}

.headerTop .navbar-menus ul .menu-items:last-child a:after {
  display: none;
}

.headerTop ul li.dropdown.menu-items ul.dropdown-menu,
ul.dropdown-submenu {
  background: var(--extra-color-1);
  border-radius: 0px;
  border: 0px;
}

.headerTop ul li.dropdown.menu-items ul.dropdown-menu li {
  display: block;
  border-image: linear-gradient(to left, #80808042, #ffffff39, #ffffff35) 1;
  border-top: 1px solid transparent;
}

.headerTop ul li.dropdown.menu-items ul.dropdown-menu li a.dropdown-item {
  padding: 9px 16px;
  min-width: 210px;
}

.headerTop
  ul
  li.dropdown.menu-items
  ul.dropdown-menu
  li
  a.dropdown-item::after {
  content: unset;
}

.headerTop
  ul
  li.dropdown.menu-items
  ul.dropdown-menu
  li
  .drop-header-btn::after {
  background-image: url("../img/drop-icon.png");
  opacity: 1;
  background-position: center right 17px;
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-size: 5px;
}

.headerTop ul li.dropdown.menu-items ul.dropdown-menu.show button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 19px;
}

.headerTop ul li.dropdown.menu-items ul.dropdown-menu li a.dropdown-item {
  min-width: 231px;
}

.headerTop ul .cart-items a .count {
  background: var(--extra-color-3);
  font-size: 9px;
  color: #000;
  font-family: "poppins-medium";
  width: 15px;
  height: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;
  top: 6px;
  right: 2px;
}

.headerTop ul li.dropdown.menu-items ul.dropdown-menu.show {
  transform: translate(0px, 57.3333px) !important;
  padding-top: 3px;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.announcement-box {
  background: #c91111db;
  color: var(--light-text-color);
  padding: 15px 0px;
}

.announcement-box .text-wrapper {
  display: flex;
  align-items: end;
}

.announcement-box .text-wrapper h4 {
  margin: 0px 30px 0px 0px;
  font-size: 30px;
}

.announcement-box .text-wrapper p {
  margin: 0;
  font-size: 15px;
  line-height: 25px;
  font-family: var(--poppin-medium);
}

@media (max-width: 1366px) {
  .headerTop ul li.dropdown.menu-items ul.dropdown-menu.show button {
    padding: 10px 16px;
  }

  .banner_sec .banner-slides .carousel-inner .carousel-caption.d-md-block p br {
    display: none;
  }
}

/* Header Css End Here */

/* Banner Sec Css Start Here */
.banner_sec .banner-slides .carousel-inner .carousel-caption.d-md-block {
  position: unset;
  padding-bottom: 0px;
}

.banner_sec .banner-slides .carousel-inner .carousel-caption.black * {
  color: #000;
}

.banner_sec
  .banner-slides
  .carousel-inner
  .carousel-caption.d-md-block
  h1.title {
  line-height: 1;
}

.banner_sec
  .banner-slides
  .carousel-inner
  .carousel-caption.d-md-block.white
  h1.title {
  text-shadow: 0 0 black !important;
}

.banner_sec
  .banner-slides
  .carousel-inner
  .carousel-caption.d-md-block.white
  h1 {
  font-size: 77px;
}

.banner_sec .banner-slides .carousel-inner .carousel-caption.white * {
  color: #fff;
}

.banner_sec .banner-slides .carousel-inner .carousel-caption.d-md-block * {
  text-shadow: unset;
  text-align: start;
}

.banner_sec .banner-slides {
  position: relative;
}

.banner_sec .banner-slides .slider-image img {
  width: 100%;
  object-fit: cover;
  height: 685px;
  filter: brightness(0.5);
}

.banner_sec .banner-slides .content-wrapper {
  position: absolute;
  top: 55px;
  left: 7.5%;
  width: 50%;
}

.carousel-control-next,
.carousel-control-prev {
  width: 33px;
  height: 33px;
  top: calc(50% - 15px);
  background: #000;
  opacity: 1;
  padding: 5px 5px 5px 1px;
}

.banner_sec .banner-slides .content-wrapper .icon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.banner_sec .banner-slides .content-wrapper p {
  max-width: 90%;
  font-size: 14px;
  line-height: 24px;
}

.banner_sec .banner-slides .content-wrapper .title {
  text-shadow: 1px 1px 4px black;
}

.banner_sec .banner-slides .content-wrapper .subtitle {
  margin: 0;
}

.banner_sec .banner-slides .bottom-copyright {
  position: absolute;
  bottom: 20px;
  right: 30px;
  color: #ffffff;
  font-family: "poppins-medium";
  text-shadow: 2px 2px 3px black;
}

@media (max-width: 1366px) {
  .banner_sec
    .banner-slides
    .carousel-inner
    .carousel-caption.d-md-block
    h1.title {
    line-height: 1;
    font-size: 67px;
  }
}

/* Banner Sec Css End Here */

/* Prayer Timing Css Start Here */
.progress {
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progress figure img {
  position: relative;
  height: 183px;
  z-index: 99;
  max-width: unset;
  top: -16px;
  left: -17px;
}

.prayer-timing {
  position: absolute;
  bottom: 20px;
  left: 111px;
}

.banner_sec .banner-slides .bottom-copyright p {
  font-size: 12px;
}

section.banner_sec {
  position: relative;
}

.prayer-timing .topbar {
  display: flex;
  justify-content: space-between;
  background-color: #1da59a;
  padding: 8px 20px 6px;
  border-top-left-radius: 12px;
  border-top-right-radius: 14px;
}

.prayer-timing .topbar h4 {
  margin-bottom: 0px;
  color: #fff;
}

.prayer-timing .table-progeress-prayer {
  display: flex;
  background-color: #ffffffc7;
  padding: 0px 18px;
  align-items: center;
}

.prayer-timing .table-progeress-prayer .table-responsive {
  width: calc(100% - 210px);
}

.prayer-timing .table-progeress-prayer .table-responsive table.table tr td {
  font-family: var(--font-heading);
  font-size: 20px;
  border: 0px;
}

.prayer-timing .table-progeress-prayer .table-responsive table.table tr th {
  border: 0px;
  color: #000000;
  font-size: 29px;
  font-family: var(--font-heading);
}

.prayer-timing
  .table-progeress-prayer
  .table-responsive
  table.table
  tr
  td:last-child {
  text-align: end;
  padding-right: 25px;
  font-weight: 800;
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}

.prayer-timing .table-progeress-prayer .table-responsive table.table tr td {
  padding: 0px 15px;
  font-size: 17px;
}

.banner-programs {
  position: absolute;
  top: 54px;
  right: 55px;
}

.banner-programs .program-topbar {
  display: flex;
  justify-content: space-between;
  background-color: #1da59a;
  padding: 8px 20px 6px;
  border-top-left-radius: 12px;
  border-top-right-radius: 14px;
}

.banner-programs .program-topbar h5 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
}

.banner-programs .all-programs {
  background: #ffffffc7;
  padding: 5px;
}

.banner-programs .programs-list {
  height: 261px;
  overflow-y: scroll;
}

/* width */
.banner-programs .programs-list::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.banner-programs .programs-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.banner-programs .programs-list::-webkit-scrollbar-thumb {
  background: #00000053;
}

/* Handle on hover */
.banner-programs .programs-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.banner-programs .programs-list .single-program {
  padding: 21px 16px 10px;
  border-bottom: 1px solid #1da59a82;
  margin-right: 11px;
}

.banner-programs .programs-list .single-program p {
  margin-bottom: 10px;
  font-weight: 400;
}

.banner-programs .programs-list .single-program .btn {
  border-radius: 5px;
}

@media (max-width: 1400px) {
  .prayer-timing .table-progeress-prayer .table-responsive table.table tr td {
    padding: 0px 15px;
    font-size: 17px;
  }

  .headerTop .navbar-menus ul .menu-items a,
  .headerTop .navbar-menus ul .menu-items .drop-header-btn {
    font-size: 11px;
    padding: 10px 6px;
  }

  section.instant-donation
    .instant-donation-box
    .heading-cards
    .atm-cards
    figure
    img {
    width: 67px !important;
    height: 29px !important;
  }

  h2 {
    font-size: 47px;
  }
}

/* Prayer Timing Css End Here */
/* Some Social Boxes CSs Start Here */
section.social-boxe {
  padding: 33px 0px;
}

section.social-boxe .social-boxes-start .single-box {
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 17px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section.social-boxe .social-boxes-start .single-box.mobile-app-back {
  background-image: url("../img/mobileapp.png");
}

section.social-boxe .social-boxes-start .single-box.youtube-back {
  background-image: url("../img/youtube.png");
}

section.social-boxe .social-boxes-start .single-box.zakat-back {
  background-image: url("../img/zakat.png");
}

section.social-boxe .social-boxes-start .single-box.volunter-back {
  background-image: url("../img/volunter.png");
}

section.social-boxe .social-boxes-start .single-box.mobile-app-back .left-text {
  width: 70%;
}

section.social-boxe .social-boxes-start .single-box.youtube-back .left-text {
  width: 66%;
}

section.social-boxe .social-boxes-start .single-box.zakat-back .left-text {
  width: 72%;
}

section.social-boxe .social-boxes-start .single-box.volunter-back .left-text {
  width: 70%;
}

section.social-boxe .social-boxes-start .single-box .left-text h5 {
  line-height: 0.8;
  margin-bottom: 7px;
}

section.social-boxe .social-boxes-start .single-box * {
  margin-bottom: 0px;
}

section.social-boxe .social-boxes-start .single-box.dark * {
  color: #000000;
}

section.social-boxe .social-boxes-start .single-box.light * {
  color: #fff;
}

section.social-boxe
  .social-boxes-start
  .single-box
  .left-text
  .button-group
  button {
  background-color: transparent;
  border: 0px;
  margin: 0px;
  display: block;
  overflow: hidden;
}

section.social-boxe .social-boxes-start .button-group.store-btns {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

section.social-boxe .social-boxes-start .button-group.store-btns button {
  max-width: calc(100% - 12px);
  display: block;
  /* width: 109px; */
  padding: 0px;
}

section.social-boxe
  .social-boxes-start
  .single-box
  .left-text
  .button-group
  button
  img {
  height: 26px;
  object-fit: cover;
}

section.social-boxe .social-boxes-start .single-box .left-text .button-group a {
  font-size: 13px;
  text-decoration: none;
  margin-top: 18px;
  display: block;
  width: fit-content;
}

section.social-boxe
  .social-boxes-start
  .single-box
  .left-text
  .button-group.zakat-btns
  a {
  color: #fec265;
}

section.social-boxe
  .social-boxes-start
  .single-box
  .left-text
  .button-group.volunter-btns
  a {
  color: #1ca499;
}

/* Some Social Boxes CSs End Here */
/* Instant Donation Start Here */
section.instant-donation {
  padding-bottom: 35px;
}

section.instant-donation .instant-donation-box .heading-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section.instant-donation .instant-donation-box .heading-cards .atm-cards {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

section.instant-donation
  .instant-donation-box
  .heading-cards
  .atm-cards
  figure
  img {
  width: 80px;
  height: 37px;
  border-radius: 5px;
}

section.instant-donation .instant-donation-box {
  background-color: #fec265;
  padding: 49px 48px 29px;
}

section.instant-donation .instant-donation-box .donation-amounts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: right;
}

section.instant-donation
  .instant-donation-box
  .donation-amounts
  .single-payment {
  background-color: #1da59a;
  border: 1px solid #fff;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 9px;
}

section.instant-donation
  .instant-donation-box
  .donation-amounts
  .single-payment
  span {
  font-family: "poppins-regular";
  font-size: 18px;
  color: #fff;
}

section.instant-donation .instant-donation-box .heading-cards .text * {
  line-height: 1.1;
}

section.instant-donation .instant-donation-box .heading-cards .text h6 {
  margin-bottom: 0px;
}

section.instant-donation .instant-donation-box .heading-cards .text h2 {
  margin-bottom: 0px;
}

section.instant-donation .instant-donation-box .heading-cards .text p {
  margin-bottom: 0px;
}

/* Instant Donation End Here */
/* Gallery Css Start Here */
section.photo-gallery {
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% 100%;
  background-image: url("../img/gallery-back.png");
  padding-top: 2px;
  padding-bottom: 45px;
}

.top-heading {
  margin: 35px 0px;
}

section.photo-gallery .gallery-start .galler-images figure img {
  height: 254px;
  object-fit: cover;
  width: 100%;
}

.top-heading {
  margin: 35px 0px;
}

section.photo-gallery .gallery-start .galler-images figure img {
  height: 254px;
  object-fit: cover;
  width: 100%;
}

section.photo-gallery.without-back {
  background-image: unset;
}

section.photo-gallery.without-back .gallery-main {
  max-height: 808px;
  overflow-y: scroll;
  overflow-x: hidden;
}

section.photo-gallery.without-back .gallery-main::-webkit-scrollbar {
  width: 7px;
}

/* Track */
section.photo-gallery.without-back .gallery-main::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
section.photo-gallery.without-back .gallery-main::-webkit-scrollbar-thumb {
  background: #fec265;
}

/* Handle on hover */
section.photo-gallery.without-back
  .gallery-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gallery-scrollabe {
  max-height: 808px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.gallery-scrollabe::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.gallery-scrollabe::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.gallery-scrollabe::-webkit-scrollbar-thumb {
  background: #fec265;
}

/* Handle on hover */
.gallery-scrollabe::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.video-scroll {
  max-height: 531px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.video-scroll::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.video-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.video-scroll::-webkit-scrollbar-thumb {
  background: #fec265;
}

/* Handle on hover */
.video-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Gallery Css End Here */
/* News Css Start Here */
section.news-relese {
  padding: 31px 0px;
}

section.news-relese .news-relese-start .news-list .single-news {
  position: relative;
}

section.news-relese .news-relese-start .news-list .single-news {
  border-bottom: 1px solid #6b6a6a1c;
  padding-top: 22px;
}

section.news-relese .news-relese-start .news-list .single-news::before {
  position: absolute;
  display: block;
  content: "";
  width: 37px;
  height: 1px;
  background-color: #1da59ade;
  top: 0px;
}

section.news-relese .news-relese-start .news-list .single-news span.date {
  padding-left: 22px;
  display: block;
  width: fit-content;
  margin: 13px 0px;
  color: #4b4b4b;
  font-size: 10px;
  background-image: url("../img/iconclock.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 13px 13px;
}

section.news-relese .news-relese-start .news-list .single-news a.colored-btn {
  color: #1da59a;
  font-size: 12px;
  text-decoration: none;
}

section.news-relese .news-relese-start .news-details.news-list .single-news {
  border-bottom: 0px;
}

section.news-relese.details-page {
  padding-bottom: 114px;
}

.news-details.news-list p {
  font-size: 12px;
  line-height: 1.25;
}

.news-relese-start .news-list .filter-the-new {
  width: 160px;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .news-relese-start .news-list .filter-the-new {
    position: absolute;
    right: 0px;
  }
}

/* News Css End Here */
/* ContactUS Css Start Here */
section.contact-us-home .form-control {
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
}

section.contact-us-home .form-group label {
  color: #ffffff;
}

section.contact-us-home {
  background-color: #1da59a;
  padding: 25px 2px;
  background-image: url("../img/about-ring.png");
  background-repeat: no-repeat;
  background-position: -25% 0%;
}

section.contact-us-home h6.small-heading {
  color: #fec265;
}

section.contact-us-home h2,
section.contact-us-home p {
  color: #fff;
}

section.contact-us-home .form-control::placeholder {
  opacity: 0.3;
  color: #ffffff;
}

.form-control:focus {
  background-color: transparent;
  box-shadow: none;
}

section.contact-us-home .contact-home-start .contact-form {
  margin-top: 34px;
}

textarea.form-control {
  height: 95px;
  padding-top: 15px;
  resize: none;
}

/* ContactUS Css End Here */
/* Footer Start Here */

/* Footer STart Here */
footer.footer ul {
  list-style: none;
  padding-left: 0px;
  padding-bottom: 0px;
}

footer.footer {
  padding: 70px 0px 12px;
}

footer.footer h6 {
  font-family: var(--font-heading);
  font-size: 23px;
}

footer.footer .footer_start .footer_text figure.logo img {
  max-width: 222px;
}

footer.footer .footer_start .footer_text p {
  font-size: 11px;
  line-height: 22px;
  margin-top: 26px;
  color: #000000;
  opacity: 0.5;
  max-width: 333px;
}

footer.footer .footer_start .footer_text ul.social_links {
  display: flex;
  gap: 13px;
  margin-top: 24px;
}

footer.footer .footer_start .footer_text ul.social_links li a img {
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
}

footer.footer .footer_start .footer_links ul {
  margin-top: 16px;
}

footer.footer .footer_start .footer_links ul li {
  padding-left: 17px !important;
  padding: 3px 0px;
  background-image: url(../img/li.png);
  background-repeat: no-repeat;
  background-position-y: 10px;
  background-position-x: left;
  transition: 0.6s all;
  background-size: 6px 11px;
}

footer.footer .footer_start .footer_links ul li a {
  text-decoration: none;
  color: #000000;
  font-family: "poppins-regular";
  font-size: 12px;
}

footer.footer .footer_start .footer_links ul li:hover {
  /* background-position: center left 7px; */
  background-position-y: 10px;
  background-position-x: 4px;
}

footer.footer .footer_start .contact_info ul {
  margin-top: 25px;
}

footer.footer .footer_start .contact_info ul li span,
footer.footer .footer_start .contact_info ul li a {
  text-decoration: none;
  color: #000000;
  font-family: "poppins-regular";
  font-size: 12px;
}

footer.footer .footer_start .contact_info ul li {
  padding: 8px 0px;
  /* padding-left: 25px; */
  background-repeat: no-repeat;
  background-position: left top 17px;
  background-size: 11px;
}

/* footer.footer .footer_start .contact_info ul li:not(:first-child) {
	border-top: 1px solid #00000070;
} */

footer.footer .footer_start .news_letter p {
  margin-top: 30px;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  max-width: 320px;
}

footer.footer .footer_start .news_letter .news_email {
  display: flex;
}

footer.footer .footer_start .news_letter .news_email input {
  width: calc(100% - 50px);
  height: 52px;
  padding-left: 16px;
  font-size: 10px;
  line-height: 22px;
  color: #000000;
  opacity: 0.5;
  border: solid #7070708a;
  border-width: 1px 0px 1px 1px;
}

footer.footer .footer_start .news_letter .news_email button.email_btn {
  width: 56px;
  border: 0px;
  background-color: #1ca499;
  /* background-image: url(../img/email.png); */
  background-position: center;
  background-size: cover;
}

footer.footer .footer_start .news_letter .news_email button.email_btn i {
  color: #fff;
}

footer.footer .footer_start .contact_info ul li {
  display: flex;
}

footer.footer .footer_start .contact_info ul li i {
  color: #1da59a;
  font-size: 16px;
  flex: 0 0 11%;
  margin-top: 2px;
}

/* footer.footer .footer_start .contact_info ul li.address {
	background-image: url(../img/mark.png);
} */

/* footer.footer .footer_start .contact_info ul li.tel_us {
	background-image: url(../img/phone.png);
} */

/* footer.footer .footer_start .contact_info ul li.email_us {
	background-image: url(../img/mail.png);
} */

/* Footer End Here */
/* Style Css End Here */

.mobileNavWrapp {
  display: none;
}

/* Starting Responsive  */

@media (max-width: 1280px) {
  .form-control {
    padding: 5px 8px;
  }
  .prayer-timing {
    left: 41px;
  }
}
@media (max-width: 1024px) {
  .banner_sec .banner-slides .bottom-copyright {
    max-width: 290px;
  }
}

@media (max-width: 991px) {
  .prayer-timing {
    position: relative;
    bottom: 0px;
    left: 0;
    width: 95%;
    margin: auto;
  }
  .banner_sec .banner-slides .bottom-copyright {
    max-width: 100%;
  }

  .banner_sec .banner-slides .bottom-copyright {
    right: 30px;
    left: 50%;
    width: 86%;
    transform: translateX(-50%);
    bottom: 20px;
  }
  .banner_sec .banner-slides .slider-image img {
    height: 485px;
  }
  /* Desktop Nav  */
  .desktopNavWrapp {
    display: none;
  }

  .mobileNavWrapp {
    display: block;
  }

  /* Desktop Nav  */
  footer.footer .footer_start .row .col-md-12 {
    order: 3;
  }

  /* Banner  */
  .banner-slides .title {
    font-size: 45px !important;
  }

  /* Banner  */
  .btn {
    padding: 13px 26px 12px !important;
    margin: 10px 0;
  }

  /* Social boxes  */
  section.social-boxe .social-boxes-start .single-box.volunter-back {
    background-size: cover;
    background-position: center top;
  }

  section.social-boxe .social-boxes-start .single-box.zakat-back,
  section.social-boxe .social-boxes-start .single-box.youtube-back,
  section.social-boxe .social-boxes-start .single-box.mobile-app-back {
    background-size: cover;
    background-position: center;
  }

  /* Social boxes  */
  /* Footer */
  .footer .footer_start .contact_info ul li i {
    flex: 0 0 18% !important;
  }

  footer.footer .footer_start .footer_links ul li {
    padding-left: 10px !important;
  }

  /* Footer  */
}

@media (max-width: 880px) {
  h3.large-heading {
    font-size: 32px;
  }
  footer.footer .footer_start .news_letter .news_email input {
    height: 40px;
    padding-left: 2px;
  }
}

@media (max-width: 768px) {
  footer.footer h6 {
    font-size: 18px;
  }
  footer.footer .footer_start .row .col-md-12 {
    order: unset;
  }

  /* Banner  */
  .banner_sec .banner-slides .slider-image img {
    height: 645px;
  }

  /* Banner  */
  .footer .footer_start .news_letter .news_email input {
    padding-left: 6px;
  }
}

@media (max-width: 767px) {
  .headerTop {
    padding: 15px 0px 5px;
  }

  /* Announcement  */
  .announcement-box .text-wrapper h4 {
    display: none;
  }

  /* Announcement  */
  /* Banner  */
  .banner_sec .banner-slides .content-wrapper p {
    font-size: 13px;
    line-height: 18px;
  }
  /* .carousel-control-prev,
  .carousel-control-next {
    display: none;
  } */
  .carousel-control-next,
  .carousel-control-prev {
    width: 18px;
    height: 33px;
  }
  .banner_sec .banner-slides .content-wrapper {
    width: 95%;
    top: 40%;
    transform: translateY(-40%);
    left: 20px;
  }

  .banner_sec .banner-slides .content-wrapper img {
    display: none;
  }

  .banner_sec .banner-slides .slider-image img {
    height: 360px;
  }

  .banner-slides .title {
    font-size: 38px !important;
  }
  .bottom-copyright p {
    margin-bottom: 6px;
  }
  /* Banner  */

  /* Prayers  */
  .prayer-timing .table-progeress-prayer .table-responsive table.table tr th {
    font-size: 22px;
  }

  .prayer-timing .table-progeress-prayer .table-responsive table.table tr td {
    font-size: 14px;
  }

  /* Prayers  */

  .banner-programs {
    position: relative;
    top: 0;
    right: 0;
    width: 95%;
    margin: 2rem auto 0;
  }

  /* Donation  */
  section.instant-donation .instant-donation-box .donation-amounts {
    justify-content: left;
    margin-top: 1.5rem;
  }

  /* Donation  */

  /* Btn  */
  .btn:not(.btn.mx-auto) {
    margin-left: 0 !important;
  }

  /* Btn  */

  /* Top Heading  */
  .top-heading {
    margin: 15px 0px;
  }

  /* Top Heading  */

  /* Footer  */
  footer.footer .footer_start .contact_info ul li i {
    flex: 0 0 9% !important;
  }

  /* Footer  */

  /* Prayer page  */
  .progress figure img {
    height: 135px;
    top: -12px;
    left: -14px;
  }

  .progress {
    width: 109px;
    height: 110px;
  }

  section.friday-sermons .sermon-videos-start figure.main-video {
    margin: 10px 0;
  }

  /* Prayer page  */
  /* Footer  */
  .footer .pt-5 {
    padding-top: 20px !important;
  }
  /* Footer  */
}

@media (max-width: 575px) {
  .banner_sec .banner-slides .content-wrapper p {
    max-width: 100%;
  }
  .sub-btn-wrapper .btn {
    padding: 10px 30px !important;
  }
  /* .banner_sec .banner-slides .content-wrapper {
    width: 95%;
    left: 10px;
    top: 12%;
    transform: translateY(-12%);
    left: 40px;
  } */
  .banner_sec .banner-slides .content-wrapper {
    left: 21px;
    top: 12%;
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%);
    width: 89%;
  }
  .banner_sec .banner-slides .bottom-copyright {
    left: 46%;
  }

  h2 {
    font-size: 34px;
  }

  h3.line-clamp-1 {
    font-size: 26px;
  }

  .top-heading img {
    max-width: 25px;
    width: 100%;
  }

  .news-relese-start .news-list .filter-the-new {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .form-group label {
    font-size: 12px;
  }
}

@media (max-width: 475px) {
  h3.line-clamp-1 {
    font-size: 22px;
  }

  /* Banner  */
  .banner-slides .title {
    font-size: 28px !important;
  }

  h4 {
    font-size: 18px;
  }

  /* Banner  */
  /* Social box  */
  section.social-boxe .social-boxes-start .single-box .left-text h5 {
    line-height: 1;
    font-size: 22px;
  }

  /* Social box  */
  section.our-policies .prayer-main-about {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  /* Announcement bar  */
  .announcement-box {
    padding: 8px 0px;
  }

  .announcement-box .text-wrapper h4 {
    font-size: 16px;
  }

  .announcement-box .text-wrapper p {
    font-size: 12px;
  }

  /* Announcement bar  */

  /* Banner  */

  /* Banner  */

  /*Prayer  */
  .prayer-timing .table-progeress-prayer .table-responsive {
    width: 100%;
  }

  .progress {
    width: 120px;
    height: 120px;
  }

  .progress figure img {
    height: 150px;
    top: -15px;
    left: -15px;
  }

  /*Prayer  */

  .prayer-timing .table-progeress-prayer .table-responsive table.table tr th {
    font-size: 25px;
  }

  section.instant-donation .instant-donation-box .heading-cards {
    gap: 9px;
  }

  section.instant-donation .instant-donation-box {
    padding: 29px 18px;
  }

  .prayer-timing .table-progeress-prayer {
    flex-direction: column;
  }
  .check-out-start .proced-thankyou {
    flex-direction: column;
    justify-content: center;
  }
  .review-add.mt-5 {
    margin-top: 0 !important;
  }
  .order-summary .data {
    padding: 3px 9px 3px;
  }
  h3.large-heading {
    font-size: 24px;
  }
}

/* Ending Responsive  */
