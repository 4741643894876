section.class-section {
  padding-bottom: 85px;
}
.tabs-list {
  display: flex;
}

.tabs-list .single-tab {
  text-decoration: none;
  padding: 10px 28px;
  position: relative;
  color: #4d4d4d;
  font-size: 12px;
  font-family: "poppins-regular";
}

.tabs-list .single-tab.active {
  background-color: #1da59a;
  color: #ffffff;
  border-radius: 3px;
}

.tabs-list .single-tab::after {
  content: "";
  position: absolute;
  display: block;
  height: 56%;
  width: 1px;
  background: #e9e9e9;
  right: 0px;
  top: 22%;
}

.tabs-list .single-tab.active::after {
  content: unset;
}

.class-table table {
  width: 100%;
  border-collapse: inherit;
  border-spacing: 0px 31px;
}

.class-table table tbody tr td:last-child {
  text-align: left;
  padding-left: 4%;
}

.class-table table thead {
  background: #1da59a;
}

.class-table table thead tr th {
  text-align: center;
  padding: 5px 9px;
  color: #ffffff;
  font-size: 17px;
  font-family: "philosopher-bold";
}

.class-table table thead tr th:nth-child(1) {
  width: 25%;
}

.class-table table tbody tr td {
  color: #636060;
  font-size: 12px;
  vertical-align: middle;
  font-family: "poppins-regular";
  text-align: center;
}

.class-table table tbody tr td:nth-child(1) {
  text-align: start;
  padding-left: 3% !important;
  padding: 0px 20px 0px 12px;
}

.class-table table tbody tr td:nth-child(1) h5 {
  font-size: 21px;
}

.class-table table tbody tr td:nth-child(1) p {
  font-size: 12px;
}

.class-table table thead tr th:nth-child(2) {
  width: 18%;
}

.class-table table thead tr th:nth-child(3) {
  width: 17%;
}

.class-table table thead tr th:nth-child(4) {
  width: 14%;
}

.class-table table thead tr th:nth-child(5) {
  text-align: left;
  padding-left: 4%;
  width: 26%;
}

.class-table table tbody tr td:not(:first-child) {
  border-left: 1px solid #e9e9e9;
}

.class-table table tbody tr::after {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  background: #707070;
  left: 0px;
  bottom: -15px;
  opacity: 0.24;
}

.class-table table tbody tr {
  position: relative;
}
.classes-start-here .speaker-wrapper ul {
  column-count: 2;
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;
}
.classes-start-here .speaker-wrapper ul li {
  color: #000;
  font-size: 13px;
}
@media (max-width: 1200px) {
  .class-table table thead tr th {
    font-size: 15px;
  }

  .class-table table tbody tr td {
    font-size: 11px;
  }

  .class-table table tbody tr td:nth-child(1) h5 {
    font-size: 19px;
  }

  .class-table table tbody tr td:nth-child(1) p {
    font-size: 11px;
  }

  .class-table table thead tr th:nth-child(1) {
    width: 27%;
  }

  .class-table table thead tr th:nth-child(2) {
    width: 19%;
  }

  .class-table table thead tr th:nth-child(3) {
    width: 18%;
  }

  .class-table table thead tr th:nth-child(4) {
    width: 15%;
  }

  .class-table table thead tr th:nth-child(5) {
    width: 21%;
  }
}

@media (max-width: 991px) {
  .class-table table thead tr th {
    font-size: 14px;
  }

  .class-table table tbody tr td {
    font-size: 11px;
  }

  .class-table table tbody tr td:nth-child(1) h5 {
    font-size: 18px;
  }

  .class-table table tbody tr td:nth-child(1) p {
    font-size: 10px;
  }

  .tabs-list .single-tab {
    padding: 10px 12px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .class-table table {
    width: 710px;
  }

  .class-table {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
