/* Faq starts here */
.faq-sec .faq-heading-wrapper {
  text-align: center;
}

.faq-sec {
  padding: 69px 0px;
}

.faq-wrapper .accordion-item {
  margin-bottom: 12px;
  border: 0px;
  border-radius: 0px;
}

.faq-sec .faq-wrapper .accordion-header {
  line-height: 1.2;
  font-family: "poppins-medium";
  border: 1px solid #6b6a6a;
}

.faq-sec .faq-wrapper .accordion-header button {
  font-size: 15px;
}

.faq-sec .faq-wrapper .accordion-button:not(.collapsed) {
  color: #ffffff;
  background: #1da59a;
  box-shadow: none !important;
}

.faq-sec .faq-wrapper .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.faq-sec .accordion-item .accordion-body {
  font-size: 14px;
}

[defaultactivekey] h2.accordion-header {
  border: 1px solid #1da59a !important;
}

section.apply-now .family-starts-here figure {
  text-align: end;
}

/* Faq ends here */
/* Applay now starts here */
.apply-now .row {
  background-image: url("../img/about-ring.png");
  background-repeat: no-repeat;
  background-position: -19% 0px;
  position: relative;
}

.apply-now .row::after {
  content: "";
  position: absolute;
  width: 100%;
  background: #1da59a;
  display: block;
  height: 93%;
  bottom: 0%;
  z-index: -1;
}

.apply-now {
  padding: 66px 0px;
}

.apply-now .apply-now-content-wrapper h6 {
  color: #fff;
  font-family: var(--font-heading);
}

.apply-now .apply-now-content-wrapper {
  padding: 54px 0px;
}

.apply-now .apply-now-content-wrapper h2 {
  color: #fff;
  font-size: 53px;
}

.apply-now .apply-now-content-wrapper p {
  color: #fff;
  font-size: 12px;
}

/* .apply-now .family-starts-here img {
	position: relative;
	top: -121px;
} */
/* Apply now ends here */
/* Study Cycle Css Start Here */
section.lectures-section .lectures-start-here .top-heading {
  color: #fff;
  background-color: #1da59a;
  padding: 12px 32px;
  background-image: url("../img/green-back.png");
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
}

section.lectures-section .lectures-start-here .top-heading h3 {
  margin-bottom: 0px;
}

section.lectures-section .lectures-start-here .top-heading p {
  margin-bottom: 11px;
}

.lecture-filter {
  display: flex;
}

.lecture-filter .norma-btn {
  background-color: transparent;
  border: 0px;
  font-size: 11px;
  font-family: "poppins-regular";
  color: #4d4d4d;
  padding: 6px 15px;
  display: block;
  text-decoration: none;
  width: fit-content;
}

.lecture-filter .norma-btn.active {
  background-color: #1da59a;
  color: #fff;
  border-radius: 4px;
}

.lecture-filter {
  border-bottom: 1px solid #70707094;
}

section.lectures-section .lectures-start-here .lecture-lists {
  display: flex;
  flex-direction: column;
}

section.lectures-section .lectures-start-here .lecture-lists .single-lecture {
  padding: 19px 0px 23px;
  border-bottom: 1px solid #70707045;
}

section.lectures-section
  .lectures-start-here
  .lecture-lists
  .single-lecture
  .text
  h4 {
  margin-bottom: 2px;
}

section.lectures-section
  .lectures-start-here
  .lecture-lists
  .single-lecture
  .text
  h4
  a {
  color: inherit;
  text-decoration: none;
}

section.lectures-section
  .lectures-start-here
  .lecture-lists
  .single-lecture
  .text
  p {
  margin-bottom: 0px;
  padding-bottom: 6px;
}

.hours-class a {
  background-image: url("../img/clock-icon.png");
  color: #636060;
  font-size: 12px;
  font-family: "poppins-regular";
  text-decoration: none;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center left;
  padding: 5px 0px 5px 24px;
}

.hours-class a {
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center left;
  padding: 5px 0px 5px 24px;
}

section.lectures-section
  .lectures-start-here
  .lecture-lists
  .single-lecture
  .text {
  padding-right: 19px;
  border-right: 1px solid #e9e9e9;
}

.paginations-start ul.pagination-list {
  padding-left: 0px;
  list-style: none;
  display: flex;
  gap: 5px;
  margin-bottom: 0px;
}

.paginations-start ul.pagination-list li a.active {
  background-color: #fec265;
}

.paginations-start ul.pagination-list li a {
  background-color: #f3f3f3;
  color: #000000;
  padding: 14px 16px;
  text-decoration: none;
  font-family: "poppins-bold";
  font-size: 11px;
}

.paginations-start {
  margin-top: 15px;
}

section.lectures-section {
  padding-top: 12px;
  padding-bottom: 50px;
}

/* Activity  */
section.activites-section
  .activities-start-here
  .activities-list
  .single-activity {
  padding: 0px 47px 35px;
  position: relative;
  border-bottom: 1px solid #7070703d;
}

section.activites-section
  .activities-start-here
  .activities-list
  .single-activity::after {
  content: "";
  position: absolute;
  display: block;
  right: 0px;
  width: 1px;
  height: calc(100% - 50px);
  top: 15px;
  background-color: #e9e9e9;
}

section.activites-section
  .activities-start-here
  .activities-list
  .column:nth-child(3)
  .single-activity::after,
section.activites-section
  .activities-start-here
  .activities-list
  .column:nth-child(6)
  .single-activity::after {
  content: unset;
}

section.activites-section
  .activities-start-here
  .activities-list
  .single-activity
  p {
  margin-bottom: 27px;
}

.apply-now.with-position .apply-now-content-wrapper {
  padding: 77px 0px 51px;
}

section.apply-now.with-position .family-starts-here figure {
  position: absolute;
  bottom: 0px;
  max-width: 95%;
}

.form-control.date-icon {
  background-image: url("../img/date-icon.png");
  background-size: 15px;
  background-position: center right 15px;
  background-repeat: no-repeat;
  padding-right: 42px;
}

section.apply-now.with-position.position-2 .family-starts-here figure {
  position: absolute;
  bottom: 0px;
  max-width: 351px;
  right: 69px;
  /* max-width: 371px; */
}

section.custom-forms.venue-booking-forn figure.venue-img img {
  height: 670px;
  object-fit: cover;
}

.hours-class a:hover {
  color: inherit;
}

@media (max-width: 991px) {
  .apply-now {
    padding: 26px 0px;
  }

  section.apply-now.with-position.position-2 .family-starts-here figure {
    position: relative;
    margin: auto;
    right: 0;
  }

  .apply-now.with-position .apply-now-content-wrapper {
    padding: 77px 0px 5px;
  }

  .apply-now .row {
    background-position: center;
  }

  h2 {
    font-size: 40px !important;
    line-height: 45px !important;
  }

  .activites-list .single-activity .member-data h4 {
    font-size: 20px !important;
  }

  .activites-list .single-activity .member-data {
    padding: 12px 7px !important;
  }

  .about-mission {
    padding: 24px 0px;
  }

  section.apply-now.with-position .family-starts-here figure {
    position: relative;
  }

  section.activites-section
    .activities-start-here
    .activities-list
    .single-activity {
    padding: 0px 20px 25px;
  }
  .apply-now .apply-now-content-wrapper {
    padding: 94px 0px 30px;
  }
  .apply-now .apply-now-content-wrapper {
    padding: 94px 0px 30px;
  }
}

@media (max-width: 880px) {
  .about-mission .about-mission-content-wrapper:last-child h2 {
    font-size: 30px;
  }

  .apply-now .apply-now-content-wrapper h2 {
    font-size: 30px !important;
    line-height: 30px !important;
  }

  .about-mission .about-mission-content-wrapper h2 {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    font-size: 30px !important;
    line-height: 30px !important;
  }
}
@media (max-width: 767px) {
  .faq-sec {
    padding: 14px 0px;
  }

  .apply-now {
    padding: 10px 0px;
  }
}

@media (max-width: 475px) {
  h2 {
    font-size: 26px !important;
    line-height: 30px !important;
  }

  .apply-now .apply-now-content-wrapper h2 {
    font-size: 26px !important;
    line-height: 30px !important;
  }

  h3.large-heading {
    font-size: 26px;
  }

  section.custom-forms .custom-form-start {
    padding: 20px 12px;
    margin-top: 16px;
  }
}
