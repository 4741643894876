.mobileNavWrapp {
  position: fixed;
  height: 100vh;
  max-width: 300px;
  width: 100%;
  right: -100%;
  top: 0;
  z-index: 999;
  background: #15958a;
  overflow-y: scroll;
  padding: 10px;
  transition: 0.4s ease;
}

.mobileNavActive {
  right: 0;
}

.mobileNavWrapp .headerTop ul li {
  display: block !important;
}

.mobileNavWrapp li {
  display: block !important;
  text-align: left;
  margin: 10px 0;
}

.mobileNavWrapp li a:after,
.mobileNavWrapp li button:after {
  display: none;
}

.navbar__close {
  position: absolute;
  top: 10px;
  right: 20px;
  height: 30px;
  width: 30px;
  z-index: 999;
}

.navbar__close button {
  border: 0;
  outline: none;
  background: transparent;
  font-size: 30px;
  color: #fff;
}

.hamburgers {
  height: 25px;
  width: 30px;
  margin-left: auto;
  transform: translateY(-40px);
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  display: none;
}

.hamburgers span {
  width: 100%;
  height: 2px;
  background: #fff;
}

@media (max-width: 991px) {
  .hamburgers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mobileNavWrapp .dropdown- .dropdown-submenu {
    /* left: 50% !important;
    transform: translateX(-50%) !important;
    top: 48px !important; */
    z-index: 999;
    background: #15958a;
    box-shadow: 0 0 5px #0000005c !important;
  }

  .mobileNavWrapp .dropdown- li {
    display: block;
    border-image: linear-gradient(to left, #80808042, #ffffff39, #ffffff35) 1;
    border-top: 1px solid transparent;
  }

  .mobileNavWrapp .dropdown- .dropdown-submenu {
    display: block;
  }
  .mobileNavWrapp .dropdown- > li:hover > .dropdown-submenu {
    display: block;
  }
  .mobileNavWrapp .dropdown- {
    background: var(--extra-color-1);
    /* box-shadow: 0 0 5px #0000005c; */
  }
}
