/* Variable Css Start Here */
/* Font cdn starts here */
@font-face {
	font-family: "philosopher-regular";
	src: url("../fonts/philosopher-regular.ttf");
}

@font-face {
	font-family: "philosopher-bold";
	src: url("../fonts/philosopher-bold.ttf");
}

@font-face {
	font-family: "poppins-regular";
	src: url("../fonts/poppins-regular.ttf");
}

@font-face {
	font-family: "poppins-medium";
	src: url("../fonts/poppins-medium.ttf");
}
@font-face {
	font-family: "poppins-bold";
	src: url("../fonts/Poppins-Bold.ttf");
}
/* Font cdn ends here */
:root {
	/* Typography starts here */
	--font-default: "poppins-regular";
	--font-heading: "philosopher-bold";
	--font-heading2: "philosopher-regular";
	--font-btn: "poppins-regular";
	--poppin-medium: "poppins-medium";
	--bold-font: "poppins-bold";
	/* Typography ends here */

	/* Colors starts here */
	--body-color: #fff;
	--extra-color-1: #1DA59A;
	--extra-color-2: #C91111;
	--extra-color-3: #FEC265;
	/* --text-color: #848696; */
	--text-color: #333333;
	--heading-color: #000000;
	--heading-color-2: #fff;
	--light-text-color: #fff;
	/* Colors ends here */

	/* button colors starts here */
	--primary-btn-color: #FEC265;
	--primary-btn-text-color: #000;
	/* button colors starts here */
	/* Auth Input Background */

}

/* Variable Css End Here */