/* About mission starts here */
.about-mission {
  padding: 68px 0px;
}
.about-mission .about-mission-content-wrapper h2 {
  font-size: 47px;
  line-height: 1;
  padding-top: 8px;
  padding-bottom: 13px;
}
.about-mission .about-mission-content-wrapper:last-child h2 {
  font-size: 40px;
}
/* About mission ends here */
/* policies starts here */
.our-policies {
  background-color: #1da59a;
  padding: 50px 0px;
  background-image: url("../img/about-ring.png");
  background-repeat: no-repeat;
  background-position: -26% 35%;
}
.our-policies .policies-list-wrapper ul {
  padding: 0px;
  list-style: none;
}
.our-policies .policies-list-wrapper ul li {
  margin: 6px 0px;
}
.our-policies .policies-list-wrapper ul li span {
  margin-left: 10px;
  color: #fff;
  font-size: 13px;
}
.our-policies .policies-content-wrapper h6 {
  color: #fff;
  font-family: var(--font-heading);
}
.our-policies .policies-content-wrapper h2 {
  color: #fff;
  font-size: 43px;
  line-height: 1;
  padding-bottom: 2px;
  padding-top: 2px;
}
.our-policies .policies-content-wrapper p {
  color: #fff;
}
.our-policies .policies-list-wrapper ul li i {
  color: #fff;
}
/* policies ends here */
/* History mission starts here */
.History-mission {
  padding: 68px 0px;
}
.History-mission .about-mission-content-wrapper h2 {
  font-size: 47px;
}
.History-mission .about-mission-content-wrapper:last-child h2 {
  font-size: 40px;
  padding-top: 9px;
  line-height: 1;
  padding-bottom: 10px;
}
.History-mission .about-img-wrapper .img-content-wrapper {
  text-align: end;
  margin-top: -27px;
}
.History-mission .about-img-wrapper .img-content-wrapper p {
  font-size: 15px;
  margin-right: 11px;
  color: #fff;
  font-weight: 700;
  text-shadow: 2px 2px #0000006b;
}
/* History mission ends here */
/* mic video starts here */
.mic-video-sec {
  padding: 60px 0;
}
.mic-video-sec .mic-content-wrapper {
  text-align: center;
}
.mic-video-sec .mic-content-wrapper h2 {
  font-size: 40px;
  line-height: 1;
  padding-top: 7px;
  padding-bottom: 6px;
}
/* mic video ends here */
/* managment starts here */
.our-managment .managment-content-wrapper {
  text-align: center;
}
.managemnet-card-wrapper .managment-img-wrapper img {
  height: 288px;
  width: 100%;
  object-fit: cover;
}
.managemnet-card-wrapper .managment-content-wrapper {
  padding: 17px 0px;
  text-align: center;
  box-shadow: 0px 1px 6px 0px #7070703d;
}
.managemnet-card-wrapper .managment-content-wrapper h4 {
  margin: 0px;
  font-size: 26px;
  color: #000000;
}
.managemnet-card-wrapper .managment-content-wrapper h5 {
  margin: 0px;
  font-size: 22px;
  color: #1da59a;
}
.our-managment .managment-row-wrapper {
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.our-managment .managment-row-wrapper .managemnet-card-wrapper {
  flex: 0 0 24%;
  margin-top: 20px;
}
.our-managment {
  padding: 60px 0px;
}
/* managment ends here */

/* Videos Css Start Here */
section.friday-sermons .sermon-videos-start .large-video img.img-fluid {
  width: 100%;
  height: 480px;
  object-fit: cover;
}

section.friday-sermons .sermon-videos-start figure.main-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

section.friday-sermons .sermon-videos-start figure.main-video img.play-icon {
  position: absolute;
  cursor: pointer;
}
section.friday-sermons .sermon-videos-start .large-video .prayer-time {
  background: #fec265;
  padding: 9px;
}
section.our-policies .prayer-main-about {
  display: flex;
  align-items: center;
}

section.our-policies .prayer-main-about table tr td {
  font-size: 21px;
  font-family: var(--font-heading);
  color: #fff;
  padding: 2px 21px;
  line-height: 1.3;
}
section.our-policies .prayer-main-about table tr {
  border: transparent;
}
.btn.btn-second {
  background: #1da59a;
  color: #fff;
  font-family: "philosopher-bold";
  font-size: 16px;
  padding: 11px 27px;
}
.btn.btn-second img {
  padding-right: 16px;
}
@media (max-width: 1400px) {
  .History-mission .about-img-wrapper .img-content-wrapper p {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .managemnet-card-wrapper .managment-content-wrapper h4 {
    font-size: 18px;
  }
  .managemnet-card-wrapper .managment-content-wrapper h5 {
    font-size: 18px;
  }
  .our-policies {
    padding: 50px 10px;
  }
}

@media (max-width: 767px) {
  .our-managment .managment-row-wrapper .managemnet-card-wrapper {
    flex: 0 0 40%;
  }
  .our-managment .btn {
    margin: auto !important;
  }
  .our-managment {
    padding: 30px 0px;
  }
  .mic-video-sec {
    padding: 0px 0;
  }
  .History-mission {
    padding: 28px 0px;
  }

  /* Prayers Page  */
  section.our-policies .prayer-main-about table tr td {
    font-size: 16px;
    padding: 2px 17px;
  }
  /* Prayers Page  */

  .our-policies {
    padding: 10px 10px;
  }
}

@media (max-width: 425px) {
  .our-policies .policies-list-wrapper ul li {
    display: flex;
    align-items: flex-start;
  }
  .our-managment .managment-row-wrapper .managemnet-card-wrapper {
    flex: 0 0 48%;
  }
  .managemnet-card-wrapper .managment-content-wrapper h5 {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .our-managment .managment-row-wrapper .managemnet-card-wrapper {
    /* flex: 0 0 95%; */
    flex: 0 0 95%;
  }
}
