.products-side-bar .categories {
  border: 1px solid #c3cad9;
  border-radius: 5px;
}

.products-side-bar {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.products-side-bar .categories h5.heading {
  background: #1da59a;
  padding: 8px 0px 8px 23px;
  color: #fff;
  margin-bottom: 0px;
}

.products-side-bar .categories ul.categories-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.products-side-bar
  .categories
  ul.categories-list
  li.single-category:not(:last-child) {
  border-bottom: 1px solid #9a9a9a42;
}

.products-side-bar .categories ul.categories-list li.single-category {
  padding: 13px 23px 14px;
  background: #f3f3f3;
}

.products-side-bar .categories ul.categories-list li.single-category a {
  color: #000000;
  text-decoration: none;
  font-size: 13px;
  font-family: "poppins-regular";
}

.products-side-bar .ads {
  background-image: url("../img/add.png");
  background-size: 100% 100%;
  background-position: center center;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 9px;
  border-radius: 5px;
}

.products-side-bar .ads .text h6 {
  margin-bottom: 0px;
  font-family: "philosopher-bold";
}

.products-side-bar .ads .text {
  text-align: center;
}

/* Sidebar End Here */
.products-side-bar .ads .text h6 {
  margin-bottom: 0px;
  font-family: "philosopher-bold";
}

.products-side-bar .ads .text {
  text-align: center;
}

.products-main .products-filters {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.products-main .products-filters .filter {
  width: 143px;
}

.products-main .products-filters .search {
  width: calc(100% - 162px);
}

.products-main .products-filters .filter label {
  position: absolute;
  top: -25px;
}

.products-main .products-filters .filter .form-group {
  position: relative;
}

.products-main .products-filters .search input {
  padding-left: 50px;
  background-image: url("../img/search-icon.png") !important;
  background-position: center left 12px;
  background-repeat: no-repeat;
  background-size: 19px;
}

.products-list .single-product {
  border-radius: 5px;
  border: 1px solid #c3cad9;
  padding: 3px;
  margin-left: 13px;
}

.products-list .single-product figure img {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 209px;
  object-fit: cover;
  margin: 0px auto;
}

.products-list .single-product .text .name-rating {
  display: flex;
  align-items: center;
}

.products-list .single-product .text .name-rating h5 {
  margin-bottom: 0px;
  width: calc(100% - 50px);
}

.products-list .single-product .text .name-rating span {
  font-size: 14px;
  font-family: "poppins-bold";
  width: 42px;
}

.products-list .single-product .text .name-rating span i {
  color: #ffcd03;
}

.products-list .single-product .text p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 7px;
}

.products-list .single-product .text .price {
  display: flex;
  align-items: center;
  gap: 6px;
}

.products-list .single-product .text .price span {
  color: #000;
  font-size: 18px;
  font-family: "poppins-medium";
}

.products-list .single-product .text .price s {
  font-size: 14px;
  font-family: "poppins-medium";
  color: #303030;
  opacity: 0.5;
}

.products-list .single-product .text a.cart-btn {
  color: #1da59a;
  font-size: 13px;
  text-decoration: none;
  font-size: 13px;
}

.products-list .single-product .text {
  padding: 23px 12px;
}

/* Product Details Css Start Here */
.product-details figure.product-image {
  border: 1px solid #c3cad9;
  padding: 5px;
  border-radius: 6px;
}

.product-details figure.product-image img {
  border-radius: 5px;
  height: 365px;
  object-fit: cover;
}

.product-details .about-product h2 {
  font-size: 42px;
  margin-bottom: 5px;
  line-height: 1.4;
}

.product-details .about-product .reviews {
  display: flex;
  gap: 6px;
}

.product-details .about-product .reviews span.rating {
  display: flex;
  gap: 1px;
}

.product-details .about-product .reviews span.rating i {
  color: #ffcd03;
}

.product-details .about-product .reviews span.total-review {
  font-size: 11px;
  font-family: "poppins-medium";
  color: #000000;
  opacity: 0.5;
}

.product-details .about-product .reviews span.total-review a {
  font-size: 11px;
  font-family: "poppins-medium";
  color: #000000;
  text-decoration: none;
}

.product-details .about-product .details-para h5.small {
  font-size: 22px;
}

.product-details .about-product .details-para {
  margin-top: 14px;
}

.product-details .about-product .details-para p {
  font-size: 12px;
}

.product-details .about-product .details-para .price {
  display: flex;
  align-items: center;
  gap: 6px;
}

.product-details .about-product .details-para .price span {
  color: #000;
  font-size: 18px;
  font-family: "poppins-medium";
}

.product-details .about-product .details-para .price s {
  font-size: 14px;
  font-family: "poppins-medium";
  color: #303030;
  opacity: 0.5;
}

.quantity-counter {
  border: 1px solid #dcdcdc7a;
  width: fit-content;
  display: flex;
}

.quantity-counter button {
  background-color: transparent;
  border: 0px;
  font-family: "poppins-medium";
  font-size: 14px;
  color: #000;
  padding: 6px 14px;
}

.quantity-counter span {
  font-size: 14px;
  font-family: "poppins-medium";
  display: block;
  padding: 10px 22px;
  border: 1px solid #dcdcdc7a;
  border-top: 0px;
  border-bottom: 0px;
}

.description-reviews .tabs-handler {
  display: flex;
  gap: 35px;
  border-top: 1px solid #dcdcdc7a;
  border-bottom: 1px solid #dcdcdc7a;
  padding: 12px 0px 11px;
}

.description-reviews .tabs-handler button {
  background-color: transparent;
  border: 0px;
  color: #000000;
  font-size: 14px;
  font-family: "poppins-regular";
  position: relative;
}

.description-reviews .tabs-handler button.active {
  font-family: "poppins-regular";
  color: #1da59a;
  font-weight: 600;
}

.description-reviews {
  padding-top: 46px;
}

.description-reviews .tabs-handler button:not(:first-child)::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #000;
  left: -16px;
  top: 5px;
  font-size: 14px;
  font-family: "poppins-regular";
  width: 1px;
  height: 15px;
}

.reviews-list .single-review {
  display: flex;
  gap: 24px;
}

.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.reviews-list .single-review .review-box {
  border: 1px solid #dcdcdc;
  width: calc(100% - 90px);
  padding: 13px 23px;
  border-radius: 6px;
}

.reviews-list .single-review figure.customer-image img {
  width: 73px;
  height: 73px;
  object-fit: cover;
}

.reviews-list .single-review .review-box .cus-info-rating {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.reviews-list .single-review .review-box .cus-info-rating .cus-info {
  display: flex;
  gap: 6px;
  align-items: center;
}

.reviews-list
  .single-review
  .review-box
  .cus-info-rating
  .cus-info
  span.cus-name {
  color: #000000;
  font-size: 16px;
  font-family: "poppins-medium";
  font-weight: 600;
}

.reviews-list
  .single-review
  .review-box
  .cus-info-rating
  .cus-info
  span.review-date {
  color: #000000;
  font-size: 12px;
  font-family: "poppins-regular";
}

.reviews-list .single-review .review-box .cus-info-rating span.cus-rating {
  display: flex;
  align-items: center;
  gap: 2px;
}

.reviews-list .single-review .review-box .cus-info-rating span.cus-rating i {
  font-size: 14px;
  color: #ffcd03;
}

/* Cart Css Start Here */
.table-cart table {
  width: 100%;
}

.table-cart table thead th {
  text-align: center;
}

.table-cart table tr td {
  text-align: center;
}

.table-cart table tr td .product-data {
  display: flex;
  align-items: center;
  gap: 41px;
}

.table-cart table tbody tr {
  border-bottom: 1px solid #70707045;
}

.table-cart table tr td .product-data span.cross {
  font-family: "poppins-bold";
  font-size: 20px;
  background: #fec265;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-cart table tr td .product-data .desc {
  text-align: start;
}

.table-cart table tr td .product-data .desc h6 {
  font-size: 18px;
  font-family: "philosopher-bold";
  margin-bottom: 0px;
}

.table-cart table tr td .product-data .desc span {
  color: #000000;
  font-size: 13px;
  font-family: "poppins-regular";
}

.table-cart table tr td {
  font-size: 18px;
  font-family: "poppins-medium";
}

.table-cart table thead th.first-name {
  text-align: start;
  padding-left: 64px;
}

.table-cart table thead th {
  background: #1da59a;
  padding: 9px 0px;
  color: #fff;
  font-family: "philosopher-bold";
}

.table-cart table thead th:nth-child(1) {
  width: 50%;
}

.table-cart table tbody tr td:nth-child(1) {
  padding: 16px 29px;
}

.table-cart table tr td .product-data .main {
  display: flex;
  align-items: center;
  gap: 20px;
}

.donation-car .donation-area {
  background-image: url("../img/yellow-bg.png");
  padding: 33px 58px 48px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
}

.donation-car .donation-area .donation-amounts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  justify-content: start;
}

.donation-car .donation-area .donation-amounts .single-payment {
  background-color: #1da59a;
  border: 1px solid #fff;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 9px;
}

.donation-car .donation-area .donation-amounts .single-payment span,
.donation-car
  .donation-area
  .donation-amounts
  .single-payment
  input::placeholder {
  font-family: "poppins-regular";
  font-size: 18px;
  color: #fff;
}

.donation-car .donation-area .donation-amounts .single-payment.input-wi {
  min-width: 82%;
}

.donation-car .donation-area .donation-amounts .single-payment input {
  padding-left: 12px;
  padding-right: 12px;
  min-width: 82%;
  font-family: "poppins-regular";
  font-size: 18px;
  color: #fff;
  border: 0px;
  background-color: transparent;
  outline: none;
}

.btn.bg-last {
  background: #1da59a;
  color: #fff;
  margin-top: 15px;
}

.donation-car .donation-area {
  padding: 33px 58px 48px;
}

.btn.bg-last {
  background: #1da59a;
  color: #fff;
  margin-top: 15px;
}

.donation-car .donation-area {
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
}

.order-summary {
  background: #f3f3f3;
  border: 1px solid #c3cad9;
  border-radius: 5px;
  padding: 19px 0px;
}

.order-summary h5 {
  padding: 0px 23px;
}

.order-summary .data {
  display: flex;
  justify-content: space-between;
  padding: 3px 24px 3px 24px;
}

.order-data.customuzed .data span.question {
  padding-top: 1px;
}

.order-summary .data span.question {
  color: #000000;
  font-size: 14px;
  font-family: "poppins-regular";
}

.order-summary .data span.answer {
  font-size: 16px;
  color: #000;
  font-family: "poppins-medium";
  width: 95px;
}

.order-summary .data-total.data {
  border-bottom: 1px solid #dcdcdc78;
  border-top: 1px solid #dcdcdc78;
  padding-top: 12px;
  margin-top: 64px;
  padding-bottom: 10px;
}

.order-summary .check-out {
  padding: 21px 20px 0px;
}

.donation-car {
  padding: 71px 0px 0px;
}

/* CheckOut Css Start Here */
.service-data .single-service {
  display: flex;
  align-items: center;
  padding: 12px 23px;
  gap: 10px;
  border-top: 1px solid #dcdcdc4c;
}

.service-data .single-service figure.service-detail-img {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.service-data .single-service figure.service-detail-img span.close {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #fec265;
  font-size: 12px;
  padding: 5px 10px 3px;
  border-top-left-radius: 5px;
  font-family: "poppins-medium";
}

.service-data .single-service h5 {
  padding-left: 0px;
  margin-bottom: 0px;
  font-size: 17px;
}

.service-data .single-service p {
  font-size: 12px;
  margin-bottom: 0px;
}

.service-data .single-service .product-data {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.service-data .single-service figure.service-detail-img img {
  width: 105px;
  height: 75px;
  object-fit: cover;
  max-width: 100%;
}

.service-data .single-service.products figure.service-detail-img img {
  border: 1px solid #c3cad9;
}

.service-data .single-service .product-data span.answer {
  font-size: 18px;
  font-family: "poppins-medium";
}

.service-data .single-service .product-data span.answer {
  font-size: 18px;
  font-family: "poppins-medium";
}

.order-summary h5.with-back {
  background-color: #1da59a;
  padding-top: 11px;
  padding-bottom: 11px;
  color: #fff;
  font-size: 19px;
  margin-bottom: 20px;
}

.order-summary h6.small-heading {
  padding-top: 43px;
  padding-left: 23px;
  padding-right: 12px;
  color: #000;
}

.product-data-check .order-summary .data-total.data {
  border-bottom: 0px;
}

.custom-check-box-default.checkout-check span.checkmark {
  border: 1px solid #636060;
  border-radius: 0px;
  width: 12px;
  height: 12px;
  top: 2px;
}

.ecommerce-products .custom-form-start select {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #183b5690;
}

.custom-check-box-default.checkout-check label.label-checkbox {
  padding-left: 30px;
}

/* .custom-check-box-default.checkout-check span.checkmark::after {
	border: solid #d55302;
	border-width: 0 2px 2px 0;
	top: 3px;
	left: 5px;
} */
.custom-check-box-default.checkout-check-2 span.checkmark {
  border: 1px solid #636060 !important;
  border-radius: 0px;
  width: 12px;
  height: 12px;
  top: 2px;
}

.custom-check-box-default.checkout-check-2 label.label-checkbox {
  padding-left: 30px;
}

/* .custom-check-box-default.checkout-check-2 span.checkmark::after {
	border: solid #d55302;
	border-width: 0 2px 2px 0;
	top: 3px;
	left: 5px;
} */
.check-out-start .custom-form-start {
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 28px 26px;
}

.check-out-start .custom-form-start {
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 28px 26px;
}

.check-out-start .proced-thankyou {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #70707078;
  padding-top: 16px;
  margin-top: 38px;
}

.check-out-start .proced-thankyou label.label-checkbox {
  margin-bottom: 0px;
}

.order-data.customuzed .data span.answer {
  width: calc(100% - 120px);
}

.thankyou-section .order-summary h5 {
  font-size: 22px;
}

.thankyou-section .order-summary h5.border-bottom {
  border-bottom: 1px solid #dcdcdc78;
}

.thankyou-section .order-summary h5.border-top {
  border-top: 1px solid #dcdcdc78;
}

.ecommerce-products button.slick-prev::before,
.ecommerce-products button.slick-next::before {
  background: #1ca499;
  border-radius: 10px;
  color: #fff;
  font-family: "FontAwesome";
  font-size: 27px;
  font-weight: 700;
  opacity: 0.7;
  padding: 4px 12px;
}

.ecommerce-products .review-add i {
  color: #ffcd03;
}

.ecommerce-products button.slick-prev:before {
  content: "\f104";
}

.ecommerce-products button.slick-next:before {
  content: "\f105";
}

.ecommerce-products button.slick-prev {
  left: -3%;
  z-index: 1;
}

.ecommerce-products .pay-detail-wrapper {
  background: #f3f3f3;
  border: 1px solid #c3cad9;
  padding: 5px 10px;
  height: 49px;
  display: flex;
  overflow: hidden;
  gap: 10px;
}

.ecommerce-products .pay-detail-wrapper input.num {
  flex: 5;
  background: transparent;
  border: 0px;
}

.ecommerce-products .pay-detail-wrapper input:focus-visible {
  outline: none !important;
}

.ecommerce-products .pay-detail-wrapper input {
  background: transparent;
  border: 0px;
}

.ecommerce-products .pay-detail-wrapper input.mm {
  flex: 1;
  width: 100%;
}

.ecommerce-products .pay-detail-wrapper input.cvc {
  flex: 1;
  width: 100%;
}

.ecommerce-products .pay-detail-wrapper input.mm::placeholder {
  color: #2a263d;
}

.ecommerce-products .pay-detail-wrapper input.cvc::placeholder {
  color: #2a263d;
}

.table-cart {
  overflow: auto;
}

@media (max-width: 991px) {
  .products-side-bar .categories ul.categories-list li.single-category {
    padding: 13px 11px 14px;
  }

  .products-list .single-product .text .name-rating {
    align-items: flex-start;
    flex-direction: column;
  }

  .products-list .single-product .text .name-rating h5 {
    width: 100%;
    font-size: 20px;
  }

  .products-list .single-product {
    margin-left: 0;
  }

  .products-list .single-product .text {
    padding: 14px 8px;
  }

  .products-list .single-product .text .price span {
    font-size: 16px;
  }

  .paginations-start ul.pagination-list li a {
    padding: 12px;
  }

  .donation-car .donation-area {
    padding: 16px 18px;
  }

  .reviews-list .single-review .review-box .cus-info-rating {
    flex-wrap: wrap;
    gap: 6px;
  }

  .reviews-list .single-review .review-box .cus-info-rating .cus-info {
    flex-wrap: wrap;
  }

  .reviews-list .single-review .review-box {
    padding: 13px 10px;
  }

  .reviews-list .single-review {
    gap: 5px;
  }
}

@media (max-width: 767px) {
  .products-list .single-product figure img {
    width: 100%;
    height: auto;
  }

  .products-main .products-filters {
    margin-top: 3rem;
  }

  .table-cart table tr td .product-data {
    gap: 16px;
  }

  .table-cart table tbody tr td:nth-child(1) {
    padding: 16px 10px;
  }

  .table-cart table tr td {
    font-size: 14px;
  }

  .donation-car .donation-area {
    padding: 20px 18px;
  }

  .donation-car {
    padding: 20px 0px 0px;
  }

  .donation-car .donation-area {
    margin: 20px 0;
  }

  .table-cart table tr td .product-data span.cross {
    font-size: 15px;
    width: 32px;
    height: 26px;
  }

  .donation-car .donation-area .donation-amounts .single-payment span,
  .donation-car
    .donation-area
    .donation-amounts
    .single-payment
    input::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .ecommerce-products .pay-detail-wrapper input.num {
    flex: 3 1;
  }
  .ecommerce-products .pay-detail-wrapper {
    flex-wrap: wrap;
    padding: 5px 0px;
  }

  .ecommerce-products .pay-detail-wrapper input {
    width: 100%;
  }
  .service-data .single-service {
    padding: 12px 7px;
  }
  .order-summary .data span {
    flex: 1;
  }
  .table-cart table thead th {
    padding: 9px 10px;
  }
  .ecommerce-products .pay-detail-wrapper input::placeholder {
    font-size: 12px;
  }
  .check-out-start .custom-form-start {
    padding: 28px 16px;
  }
}
