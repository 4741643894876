section.green-section .green-start-here {
  display: flex;
  align-items: center;
  gap: 25px;
  background-image: url("../img/green2.png");
  background-size: 100% 100%;
  background-color: #1da59a;
  height: 190px;
  background-position: center right;
  padding: 5px 54px;
}

section.green-section .green-start-here .logo figure img {
  width: 163px;
  height: 85px;
  object-fit: contain;
}

section.green-section .green-start-here .para p {
  margin-bottom: 0px;
  font-size: 21px;
  font-family: "philosopher-bold";
  color: #fff;
}

section.green-section .green-start-here .para {
  padding-left: 17px;
}

/* Cometition Table 1 */
section.competiion-table {
  padding: 50px 0px 30px;
}

section.competiion-table .competition-table-start table {
  width: 100%;
}

section.competiion-table .competition-table-start table thead {
  background-color: #1da59a;
}

section.competiion-table .competition-table-start table thead th {
  font-size: 17px;
  font-family: "philosopher-bold";
  color: #fff;
}

section.competiion-table .competition-table-start table thead th:nth-child(1) {
  padding: 5px 42px;
  width: 26%;
}

section.competiion-table .competition-table-start table thead th:nth-child(2) {
  padding: 5px 47px;
}

section.competiion-table
  .competition-table-start
  table
  tbody
  tr
  td:not(:first-child)::before {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: calc(100% - 24px);
  left: 0px;
  top: 12px;
  background: #e9e9e9;
}

section.competiion-table .competition-table-start table tbody tr td {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #70707040;
}

section.competiion-table
  .competition-table-start
  table
  tbody
  tr
  td:nth-child(2) {
  padding-left: 43px;
  font-size: 14px;
  font-family: "poppins-regular";
}

section.competiion-table
  .competition-table-start
  table
  tbody
  tr
  td:nth-child(1) {
  font-size: 17px;
  font-family: "philosopher-bold";
  padding-left: 38px;
}

/* Table 2 Css Start Here */
section.competiion-table-1 .competition-table-1-start table {
  width: 100%;
}

section.competiion-table-1 .competition-table-1-start table thead th {
  font-size: 17px;
  font-family: "philosopher-bold";
  color: #fff;
  background-color: #1da59a;
  padding: 5px 6px;
}

section.competiion-table-1 .competition-table-1-start table tbody tr td {
  position: relative;
  padding: 12px 10px;
  color: #636060;
  border-bottom: 1px solid #70707040;
  font-size: 14px;
  font-family: "poppins-regular";
}

section.competiion-table-1 .competition-table-1-start table thead th {
  width: 18%;
}

section.competiion-table-1
  .competition-table-1-start
  table
  thead
  th:nth-child(1) {
  width: 11%;
}

section.competiion-table-1
  .competition-table-1-start
  table
  thead
  th:nth-child(2) {
  width: 37%;
}

section.competiion-table-1
  .competition-table-1-start
  table
  thead
  th:nth-child(3) {
  width: 13%;
}

section.competiion-table-1
  .competition-table-1-start
  table
  thead
  th:nth-child(4) {
  width: 11%;
}

section.competiion-table-1
  .competition-table-1-start
  table
  thead
  th:nth-child(5) {
  width: 12%;
}
p.pihlo-bold {
  font-size: 17px;
  font-family: "philosopher-bold";
  margin-top: 23px;
  margin-bottom: 0px;
}

.competition-table-1-start {
  overflow: auto;
}
.competition-table-start {
  overflow: auto;
}
@media (max-width: 767px) {
  section.green-section .green-start-here {
    gap: 10px;
    flex-wrap: wrap;
    background-size: cover;
    height: auto;
    padding: 5px 4px;
  }
  .members-list .single-member .member-data h4 {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .members-list .single-member .member-data {
    padding: 16px 4px;
  }
  .members-list .single-member .member-data h5.small-pnik-heading {
    font-size: 16px;
  }
}

@media (max-width: 365px) {
  section.competiion-table
    .competition-table-start
    table
    tbody
    tr
    td:nth-child(1),
  section.competiion-table
    .competition-table-start
    table
    tbody
    tr
    td:nth-child(2) {
    padding-left: 18px;
  }
  section.competiion-table
    .competition-table-start
    table
    thead
    th:nth-child(1) {
    padding: 5px 30px;
  }
}
