section.custom-forms {
  padding: 51px 0px 115px;
}

section.custom-forms .custom-form-start {
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 28px 26px;
  margin-top: 66px;
}

.top-heading.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

section.custom-forms .top-heading p {
  font-size: 12px;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
}

textarea.form-control.lg {
  height: 145px;
}

.form-group.multi-select .tags-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
}

.form-group.multi-select .tags-list span.single-tag {
  position: relative;
  border: 1px solid #c3cad9;
  background: #f3f3f3;
  border-radius: 4px;
  color: #151624;
  font-size: 12px;
  font-family: "poppins-regular";
  padding: 7px 17px;
  cursor: pointer;
}

.form-group.multi-select .tags-list span.single-tag span {
  position: absolute;
  top: 0px;
  right: 6px;
  font-size: 11px;
}

/* File Uploader Custom Static Css Start Here */
.file-uploader-custom label.fileuploader {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  height: 80px;
  font-size: 11px;
  color: #183b568f;
  font-family: "Montserrat", sans-serif;
  border: 2px dashed #c3cad9;
  cursor: pointer;
}

.file-uploader-custom .files-list {
  display: flex;
  flex-direction: column;
  border: 1px solid #c3cad98a;
  border-radius: 1px;
  margin-top: 14px;
}

.file-uploader-custom .files-list .single-file {
  display: flex;
  justify-content: space-between;
  padding: 10px 19px 6px;
  align-items: center;
}

.file-uploader-custom .files-list .single-file:not(:last-child) {
  border-bottom: 1px solid #e8e1e130;
}

.file-uploader-custom .files-list .single-file .icon-text span {
  font-size: 11px;
  color: #000;
  font-family: "poppins-regular";
}

.file-uploader-custom .files-list .single-file span.removefile {
  font-size: 11px;
  color: #db4c4c;
  font-family: "poppins-regular";
  cursor: pointer;
}

.file-uploader-custom .files-list .single-file .icon-text {
  display: flex;
  align-items: center;
  gap: 9px;
}

.file-uploader-custom .files-list .single-file .icon-text img {
  width: 11px;
  height: 11px;
}

h6.form-inner-heading {
  font-size: 21px;
  font-family: var(--font-heading);
}

.form-group small.notes-line {
  display: block;
  font-size: 10px;
  font-family: "poppins-regular";
  opacity: 0.5;
  color: #000;
  margin-bottom: 15px;
}

/* Points Box Im Form Start Here */
section.custom-forms .main-point-box-shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 52px 26px;
}

section.custom-forms .main-points-service ol {
  padding-left: 15px;
}

section.custom-forms .main-points-service ol li {
  font-size: 12px;
  font-family: var(--font-default);
  line-height: 1.3;
  padding-left: 4px;
}

section.custom-forms .main-points-service p {
  font-size: 12px;
}

section.custom-forms .main-points-service ul {
  list-style: none;
  padding-left: 0px;
  margin-top: 4px;
}

section.custom-forms .main-points-service ul li {
  font-size: 12px;
  font-family: var(--font-default);
  line-height: 1.3;
}

.custom-forms .custom-form-start i {
  font-size: 12px;
  margin-bottom: 20px;
  color: #000;
  opacity: 66%;
  font-style: normal;
}

.custom-forms .custom-form-start a.terms-link {
  color: #1ca499;
  text-decoration: none;
}

.custom-forms label small {
  color: #000;
  font-size: 10px;
  opacity: 50%;
}

/* Points Box Im Form End Here */
/* File Uploader Custom Static Css End Here */

@media (min-width: 1200px) {
  section.custom-forms.venue-booking-forn .form-group {
    margin-bottom: 14px !important;
  }

  section.custom-forms.venue-booking-forn .form-group input {
    height: 47px;
  }

  section.custom-forms.venue-booking-forn .form-group label {
    margin-bottom: 7px;
  }
}

.sub-btn-wrapper .btn {
  padding: 13px 63px 12px 63px !important;
}

@media (max-width: 767px) {
  .custom-forms .custom-form-start {
    padding: 20px 14px;
  }

  .custom-forms {
    padding: 55px 0px 55px !important;
  }

  .custom-forms .custom-form-start {
    margin-top: 26px;
  }

  .custom-forms .main-point-box-shadow {
    padding: 22px 0px !important;
  }
}

@media (max-width: 576px) {
  .custom-forms .custom-form-start {
    padding: 26px 15px;
  }
  section.custom-forms .custom-form-start {
    padding: 28px 10px;
  }
}
