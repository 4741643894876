section.team-members {
  padding: 60px 0px 90px;
}
.members-list .single-member a {
  text-decoration: none;
}
.members-list .single-member .member-data {
  text-align: center;
  padding: 23px 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 8px 6px -4px;
}
.members-list .single-member .member-data h4 {
  color: #000000;
  font-size: 26px;
  font-family: "philosopher-bold";
  margin-bottom: 0px;
}

.members-list .single-member .member-data h5.small-pnik-heading {
  color: #1da59a;
  font-size: 19px;
  margin-bottom: 0px;
  font-family: "philosopher-bold";
}

.members-list .single-member figure img {
  height: 240px;
  object-fit: cover;
}

.conference-list .single-conference .confoerence-data .text h6.small-heading {
  margin-bottom: 0pc;
}

.conference-list .single-conference .confoerence-data .text h3.large-heading {
  line-height: 1;
}

.conference-list .single-conference .confoerence-data .text p {
  font-size: 13px;
}

.conference-list .single-conference .confoerence-data .speakers h5 {
  color: #191919;
  font-size: 21px;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .members-list
  .single-member
  .member-data
  h4 {
  font-size: 12px;
  line-height: 1.2;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .members-list
  .single-member
  .member-data
  h5.small-pnik-heading {
  font-size: 9px;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .members-list
  .single-member
  figure
  img {
  height: 112px;
}

.conference-list
  .single-conference
  .confoerence-data
  .speakers
  .members-list
  .single-member
  .member-data {
  padding: 10px 4px 8px;
}
@media (max-width: 1400px) {
  .conference-list .single-conference .confoerence-data .text h3.large-heading {
    font-size: 38px;
  }

  .conference-list .single-conference .confoerence-data .text p {
    font-size: 12px;
  }

  .conference-list
    .single-conference
    .confoerence-data
    .speakers
    .members-list
    .single-member
    figure
    img {
    height: 100px;
  }
}

@media (max-width: 991px) {
  .members-list .single-member .member-data h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .members-list .single-member .member-data h5.small-pnik-heading {
    font-size: 16px;
  }
  .members-list .single-member .member-data {
    padding: 16px 4px;
  }
}

@media (max-width: 767px) {
  .members-list .single-member figure img {
    height: auto;
  }
}
