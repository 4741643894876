.contact-us {
	padding: 60px 0px;
}
.contact-us .contact-form-wrapper .form-group label {
	color: #000;
}
.contact-us .contact-form-wrapper .form-group input {
	background: #f3f3f3;
	border: 1px solid #c3cad9;
}
.contact-us .contact-form-wrapper .form-group input::placeholder {
	color: #b1bbc4;
}
.contact-us .contact-form-wrapper .form-group textarea {
	background: #f3f3f3;
	border: 1px solid #c3cad9;
}
.contact-us .contact-form-wrapper .form-group textarea::placeholder {
	color: #b1bbc4;
}
.contact-us .contact-form-wrapper .form-group {
	margin-bottom: 18px;
}
.contact-us .contact-card-wrapper {
	background-color: #1da59a;
	padding: 40px 25px;
	height: 100%;
	background-image: url("../img/about-ring.png");
	background-repeat: no-repeat;
	background-position: 183% -215px;
}
.contact-us .contact-card-wrapper .contact-content-wrapper h6 {
	color: #fff;
}
.contact-us .contact-card-wrapper .contact-content-wrapper h2 {
	color: #fff;
}
.contact-us .contact-card-wrapper .contact-content-wrapper p {
	color: #fff;
}
